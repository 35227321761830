<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction } from '../../../command/netTool'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
import TableCard from './TableCard'
export default {
  name: 'travelDetail',
  data() {
    return {
      ...api.command.getState(),
      labelOne: [],
      labelTwo: [],
      labelThree: [],
      typeObj: {
        scenicLabel: null,
        farmExperiencePick: null,
        experienceFish: null
      },
      inData: [],
      detail: {
        linkMonthCode: [],
        farmTravelCalendarStoreVOS: []
      }
    }
  },
  watch: {},
  mounted() {
    const { id } = this.$route.query
    if (id) {
      // 获取详情
      api.command.getDetail
        .call(this, {
          url: `/base/farmTravelCalendar/${id}`
        })
        .then(result => {
          // eslint-disable-next-line prettier/prettier
          ;[
            {
              key: result.scenicLabel,
              fun: this.getActionOne
            },
            {
              key: result.farmExperiencePick,
              fun: this.getActionTwo
            },
            {
              key: result.experienceFish,
              fun: this.getActionThree
            }
          ].forEach(e => e.fun(this.getData(e.key)))
          this.detail.linkMonthCode = this.getData(result.linkMonthCode)
          this.detail.farmTravelCalendarStoreVOS = result.farmTravelCalendarStoreVOS.map(e => {
            return {
              ...e,
              subTile: e.subTile ? e.subTile : ''
            }
          })
          this.$forceUpdate()
        })
    } else {
      this.getActionOne()
      this.getActionTwo()
      this.getActionThree()
    }
    getAction('/api/base/farmTravelMonth/getList').then(e => {
      if (e.code == 200) {
        let data = e.data.map(e => {
          return {
            name: e.monthName,
            value: `${e.month}`
          }
        })
        this.inData = data
      } else {
        this.$message.error(e.msg)
      }
    })
  },
  methods: {
    getData(value) {
      return typeof value == 'string' ? value.split(',') : []
    },
    getActionOne(light = []) {
      // 景点（观赏类）标签
      getAction('/api/base/farmLabelData/getByCode?code=scenic_label').then(e => {
        if (e.code == 200) {
          this.labelOne = e.data.map(e1 => ({
            ...e1,
            name: e1.labelName,
            light: light.indexOf(String(e1.id)) != -1
          }))
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    getActionTwo(light = []) {
      //  农事体验 - 采摘
      getAction('/api/base/farmLabelData/getByCode?code=farm_experience_pick').then(e => {
        if (e.code == 200) {
          this.labelTwo = e.data.map(e1 => ({
            ...e1,
            name: e1.labelName,
            light: light.indexOf(String(e1.id)) != -1
          }))
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    getActionThree(light = []) {
      // 农事体验 - 垂钓
      getAction('/api/base/farmLabelData/getByCode?code=farming_experience_fish').then(e => {
        if (e.code == 200) {
          this.labelThree = e.data.map(e1 => ({
            ...e1,
            name: e1.labelName,
            light: light.indexOf(String(e1.id)) != -1
          }))
        } else {
          this.$message.error(e.msg)
        }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '活动标题',
            type: 'input',
            cols: 12,
            key: 'activityTitle',
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '活动副标题',
            type: 'input',
            cols: 12,
            key: 'activitySubTitle',
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '排序序号（0-100）',
            type: 'inputNumber',
            cols: 12,
            key: 'sort',
            props: {
              min: 0,
              max: 100
            },
            rules: [
              {
                required: true,
                type: 'number'
              }
            ]
          },
          {
            name: '关联月份',
            type: 'select',
            cols: 12,
            props: {
              mode: 'multiple'
            },
            key: 'linkMonthCode',
            typeData: this.inData
            // rules: [
            //   {
            //     required: true
            //   }
            // ]
          }
        ]
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: '活动背景',
        data: [
          {
            type: 'actionUpload',
            cols: 24,
            key: 'activityBackground',
            is_show: false
          }
        ]
      }
    },

    getForm4() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style={{ color: '#f5222d' }}>*</span>Banner（尺寸750*420PX）限制1张
          </div>
        ),
        data: [
          {
            type: 'upload',
            maxLength: 1,
            cols: 24,
            key: 'bannerUrl',
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string'
              }
            ]
          }
        ]
      }
    },

    getForm5() {
      return {
        type: 'row',
        children: [
          <CardIconLabelLocal
            isAddBtn={false}
            title={'商家包含标签 - 景点（观赏类）'}
            pubBool={false}
            cols={24}
            style={{ marginBottom: '10px', minHeight: '200px' }}
            icons={this.labelOne}
          />
        ]
      }
    },

    getForm6() {
      return {
        type: 'row',
        children: [
          <CardIconLabelLocal
            isAddBtn={false}
            title={'商家包含标签 - 农事体验 - 采摘'}
            pubBool={false}
            cols={24}
            style={{ marginBottom: '10px', minHeight: '200px' }}
            icons={this.labelTwo}
          />
        ]
      }
    },

    getForm7() {
      return {
        type: 'row',
        children: [
          <CardIconLabelLocal
            isAddBtn={false}
            title={'商家包含标签 - 农事体验 - 垂钓'}
            pubBool={false}
            cols={24}
            style={{ marginBottom: '10px', minHeight: '200px' }}
            icons={this.labelThree}
          />
        ]
      }
    },
    getForm3() {
      if (!this.detail.farmTravelCalendarStoreVOS) return null
      return {
        type: 'row',
        children: [
          <TableCard
            ref={'tableData'}
            title={'活动参与商家'}
            titleCopy={'（前3名商家需要添加副标题）'}
            dataSource={this.detail.farmTravelCalendarStoreVOS}
            typeObjData={{
              scenicLabel: this.getType(this.labelOne),
              farmExperiencePick: this.getType(this.labelTwo),
              experienceFish: this.getType(this.labelThree)
            }}
            searchIndex={this.detail.labelType}
            numberBusinesses={this.detail.shopCount}
            delShopIds={this.detail.delShopIds}
          />
        ]
      }
    },
    getType(value = []) {
      let arrOneList = []
      value.forEach(e => {
        if (e.light) {
          arrOneList.push(e.id)
        }
      })
      return arrOneList.toString()
    },

    dealData() {
      let arrOneList = []
      this.labelOne.forEach(e => {
        if (e.light) {
          arrOneList.push(e.id)
        }
      })
      let arrTwoList = []
      this.labelTwo.forEach(e => {
        if (e.light) {
          arrTwoList.push(e.id)
        }
      })
      let arrThreeList = []
      this.labelThree.forEach(e => {
        if (e.light) {
          arrThreeList.push(e.id)
        }
      })

      this.typeObj.scenicLabel = arrOneList.toString()
      this.typeObj.farmExperiencePick = arrTwoList.toString()
      this.typeObj.experienceFish = arrThreeList.toString()
    },
    getFoot() {
      let that = this
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: () => {
            this.dealData()

            let linkMonth = []
            this.detail.linkMonthCode.forEach(e => {
              this.inData.forEach(e1 => {
                if (e1.value == e) {
                  linkMonth.push(e1.name)
                }
              })
            })
            let activityBackground = (
              this.detail.activityBackground.find(e => e.select) || {
                id: 0
              }
            ).id

            if (!activityBackground) {
              this.$message.warning('请选择活动背景图！')
              return
            }

            let farmTravelCalendarStoreDTOS = this.$refs.tableData.dataSource
            try {
              farmTravelCalendarStoreDTOS.forEach(e => {
                if (e.subTile == '' || e.subTile == null) {
                  this.$message.warning('请完善自定义副标题！')
                  throw new Error('结束')
                }
              })
            } catch (error) {
              console.log(error)
              return
            }

            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: '/base/farmTravelCalendar',
                params: {
                  ...this.detail,
                  linkMonth: linkMonth.toString(),
                  linkMonthCode: this.detail.linkMonthCode.toString(),
                  scenicLabel: this.typeObj.scenicLabel,
                  farmExperiencePick: this.typeObj.farmExperiencePick,
                  experienceFish: this.typeObj.experienceFish,
                  labelType: this.$refs.tableData.searchIndex,
                  shopCount: this.$refs.tableData.numberBusinesses,
                  farmTravelCalendarStoreDTOS: this.$refs.tableData.dataSource,
                  activityBackground: activityBackground,
                  delShopIds: this.$refs.tableData.delShopIdsCopy.toString()
                },
                isPost: false
              })
              .then(result => {
                this.$router.push('/marketingCenter/travel')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.push('/marketingCenter/travel')
        }
      ]
      const right = [
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/base/farmTravelCalendar/${this.detail.id}`,
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/travel')
            }, 500)
          }
        }
      ]
      return {
        left,
        right: this.detail.id && right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        data={[
          this.getForm1(),
          this.getForm2(),
          this.getForm4(),
          this.getForm5(),
          this.getForm6(),
          this.getForm7(),
          this.getForm3()
        ].filter(e => e)}
      />
    )
  },
  renderError(h, error) {
    console.log('error', error)
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
